@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', serif;
}

.leaflet-container {
    height: 500px;
    width: 100%;
    margin: 0 auto;
}

img {
    max-width: 100%;
}

iframe {
    height: 100% !important;
}

.MuiButton-root {
    text-transform: none !important;
}

